import React, { useEffect } from "react";
import styled from "styled-components";
import { navigate } from "gatsby-link";

import SEO from "../SEO";
import { PaddingContainer } from "../Containers";
import { H1 } from "../Text";
import { AccountForm } from ".";
import { BaseButton } from "../Buttons";
import HeaderContainer from "../HeaderContainer";

import { useAuth } from "../../hooks/useAuth";
import LanguageProps from "../../interfaces/LanguageProps";

const SignOutButton = styled(BaseButton)`
  padding: 0;
  letter-spacing: 1px;
  font-weight: normal;
  text-transform: uppercase;
  border-bottom: 4px solid red;
`;

interface AccountProps extends LanguageProps {}

const Account = ({ language }: AccountProps) => {
  const { isAuthenticated, signOut } = useAuth();
  const isEnglish = language === "en";

  useEffect(() => {
    if (isAuthenticated === false) navigate(isEnglish ? "/en/login" : "/login");
  }, [isAuthenticated]);

  return (
    <>
      <SEO title="Account" lang={language} pathName="/app/account/" />
      <PaddingContainer>
        <HeaderContainer>
          <H1>Account</H1>
          <SignOutButton onClick={signOut}>
            {isEnglish ? "Log out" : "Uitloggen"}
          </SignOutButton>
        </HeaderContainer>
        <AccountForm language={language} />
      </PaddingContainer>
    </>
  );
};

export default Account;
