import React from "react";
import { Router } from "@reach/router";

import { Account } from "../../components/Account";

const App = () => (
  <Router basepath="/app">
    <Account path="/account" language="nl" />
    <Account path="/en/account" language="en" />
  </Router>
);

export default App;
