import React from "react";
import styled from "styled-components";

interface HeaderContainer {
  columns: number;
}

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
`;

interface HeaderContainerProps {
  className?: string;
}

const HeaderContainer: React.FC<HeaderContainerProps> = ({
  className,
  children,
}) => <Container className={className}>{children}</Container>;

export default HeaderContainer;
